<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="mb-5">
        <v-toolbar flat class="mb-3">
          <h1 class="headline black--text">
            {{ $t('suscripciones.suscripciones') }}
          </h1>
          <v-spacer />
        </v-toolbar>
        <v-card id="suscripcionescontratadas">
          <div v-if="loading" class="text-center pa-5">
            <v-progress-circular width="2" color="primary" indeterminate />
          </div>
          <div v-if="!loading">
            <div v-if="no_suscripciones" class="pa-5 text-center grey--text">
              {{ $t('suscripciones.sin_suscripciones_contratadas') }}
            </div>
            <div v-for="(item, index) in suscripciones" :key="item.title" class="item_pack_div" ripple>
              <v-layout wrap>
                <v-flex xs7>
                  <h5>
                    <b>{{ item.fullName }} - {{ item.nombre }}</b>
                  </h5>
                </v-flex>
                <v-flex xs5>
                  <span class="fecha_video">{{ item.fecha }}</span>
                </v-flex>
                <v-flex xs12> {{ item.descripcion }}. {{ item.detalles }} </v-flex>
                <v-flex xs10 class="motivo_suscrs">
                  <span>
                    <v-chip label color="green" text-color="white">
                      <v-icon left>mdi-label</v-icon>{{ item.importe }} {{ moneda }}
                    </v-chip>
                    <v-chip label color="teal darken-1" text-color="white">
                      {{ $t('suscripciones.mensajeria_restantes') }}: {{ item.num_mensajerias }}
                    </v-chip>
                    <v-chip label color="cyan darken-1" text-color="white">
                      {{ $t('suscripciones.videoconsulta_restantes') }}: {{ item.num_videoconsultas }}
                    </v-chip>
                  </span>
                </v-flex>
                <v-flex v-if="item.estado === 1" xs2 class="text-right botones_video">
                  <v-btn icon dark color="red" @click="mostrarCancelar(item, index)"><v-icon>mdi-close</v-icon></v-btn>
                </v-flex>
              </v-layout>
              <v-divider />
            </div>
          </div>

          <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('suscripciones.cancelar_suscripcion') }}</span>
              </v-card-title>
              <v-card-text>
                <p>{{ $t('suscripciones.cancelar_suscripcion_texto', { dia: fecha }) }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="dialog_confirm.dialog = false">{{
                  $t('common.cancel')
                }}</v-btn>
                <v-btn
                  color="red darken-1"
                  depressed
                  @click="
                    dialog_confirm.dialog = false;
                    cancelarSuscripcion();
                  "
                  >{{ $t('common.confirm') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Snackbar para mostrar avisos -->
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="alerta.color"
            top="top"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
          >
            <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
            >&nbsp;{{ alerta.texto }}
          </v-snackbar>
        </v-card>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { nameEnv, suscripcionesContratadasUrl, cancelarSuscripcionUrl, getHeader } from '../config/config';

export default {
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      dialog: false,
      tab: 0,
      loading: true,
      suscripciones: [],
      no_suscripciones: false,
      snackbar: false,
      dialog_confirm: {
        name: '',
        dialog: false,
      },
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      fecha: '',
      disabled: false,
      suscripcion_edit: {
        indice: 0,
        suscripcion: {
          id: 0,
        },
      },
      es_dingdoc: nameEnv === 'dingdoc',
      moneda: nameEnv === 'dingdoc' ? '₡' : '€',
    };
  },
  created() {
    this.$http.post(suscripcionesContratadasUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
      if (response.status === 200 && response.data) {
        this.suscripciones = response.data.suscripciones;
        this.no_suscripciones = this.suscripciones.length === 0 ? true : false;
      }
      this.loading = false;
    });
  },
  methods: {
    mostrarCancelar(item, index) {
      this.fecha = item.fecha_renew;
      this.dialog_confirm.dialog = true;
      this.suscripcion_edit.indice = index;
      this.suscripcion_edit.suscripcion = item;
    },
    cancelarSuscripcion() {
      if (!this.disabled) {
        this.disabled = true;
        this.$http
          .post(
            cancelarSuscripcionUrl,
            { user_id: this.authUser.id, suscripcion_id: this.suscripcion_edit.suscripcion.id },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.alerta.texto = this.$t('suscripciones.cancelado_correctamente');
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-delete';
              this.suscripciones[this.suscripcion_edit.indice].estado = 2;
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.snackbar = true;
            this.disabled = false;
          });
      }
    },
  },
};
</script>

<style>
.loading_pack {
  margin-top: 10px;
  text-align: center;
}
.item_pack_div {
  margin: 10px;
}
.motivo_suscr {
  margin-top: -20px;
  color: #5d5d5d;
}
</style>
